<script setup lang="ts">
import { inject, watch, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useMutation, useQuery } from '@tanstack/vue-query';
import * as yup from 'yup';
import type { AxiosError } from 'axios';
import * as rutHelpers from 'rut-helpers';
import { caregiversApi, type CaregiverForm } from '@/api/caregivers';
import { countriesApi } from '@/api/countries';
import { communesApi } from '@/api/communes';
import { type SerializedUser } from '@/api/users';
import GoBackButton from '@/components/go-back-button.vue';
import { isNumber } from '@/utils/number-functions';

const { t } = useI18n();

const currentUser = inject<Ref<SerializedUser>>('currentUser');

interface Props {
  caregiver: CaregiverForm | undefined;
  mode: 'new' | 'edit';
}

const props = defineProps<Props>();

const validationSchema = yup.object({
  firstName: yup.string().required(t('caregiverForm.fieldRequired')),
  lastName: yup.string().required(t('caregiverForm.fieldRequired')),
  // email: yup.string().email().required(t('caregiverForm.fieldRequired')),
  // phoneNumber: yup.string().required(t('caregiverForm.fieldRequired')),
  countryId: yup.string().required(t('caregiverForm.fieldRequired')),
  // address: yup.string().required(t('caregiverForm.fieldRequired')),
  // dateOfBirth: yup.string().required(t('caregiverForm.fieldRequired')),
  // description: yup.string().required(t('caregiverForm.fieldRequired')),
  dni: yup.string().nullable()
    .test('valid-dni', t('caregiverForm.invalidDni'), (value) => (value ? rutHelpers.rutValidate(value) : true)),
  // .required(t('caregiverForm.fieldRequired')),
  // sex: yup.string().required(t('caregiverForm.fieldRequired')),
  // career: yup.string().required(t('caregiverForm.fieldRequired')),
});

const initialValues = { ...props.caregiver } || {
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  passwordIsSet: false,
  phoneNumber: undefined,
  countryId: undefined,
  address: undefined,
  dateOfBirth: undefined,
  description: undefined,
  dni: undefined,
  sex: undefined,
  career: undefined,
};

const { handleSubmit, values, errors, defineField } = useForm<CaregiverForm>({ validationSchema, initialValues });

const [idCode, idCodeAttrs] = defineField('idCode');
const [firstName, firstNameAttrs] = defineField('firstName');
const [lastName, lastNameAttrs] = defineField('lastName');
const [email, emailAttrs] = defineField('email');
const [passwordIsSet, passwordIsSetAttrs] = defineField('passwordIsSet');
const [phoneNumber, phoneNumberAttrs] = defineField('phoneNumber');
const [countryId, countryIdAttrs] = defineField('countryId');
const [address, addressAttrs] = defineField('address');
const [communeId, communeIdAttrs] = defineField('communeId');
const [dateOfBirth, dateOfBirthAttrs] = defineField('dateOfBirth');
const [dni, dniAttrs] = defineField('dni');
const [sex, sexAttrs] = defineField('sex');
const [description, descriptionAttrs] = defineField('description');
const [career, careerAttrs] = defineField('career');
const [comments, commentsAttrs] = defineField('comments');

const careers = [
  { title: 'Cuidador/a', value: 'caregiver' },
  { title: 'TENS', value: 'nurse_technician' },
  { title: 'Enfermero/a', value: 'nurse' },
  { title: 'Auxiliar de enfermería', value: 'auxiliary_nurse' },
  { title: 'Terapeuta ocupacional', value: 'occupational_therapist' },
  { title: 'Kinesiólogo/a', value: 'kinesiologist' },
  { title: 'Fonoaudiólogo/a', value: 'phonoaudiologist' },
  { title: 'Médico', value: 'doctor' },
  { title: 'Podólogo/a', value: 'podologist' },
];

watch(dni, (value) => {
  dni.value = rutHelpers.rutFormat(value);
});

const { data: countries } = useQuery({
  queryKey: ['countries'],
  queryFn: () => countriesApi.index(),
});

const { data: communes } = useQuery({
  queryKey: ['communes'],
  queryFn: () => communesApi.index(),
});

const { mutate: createCaregiver, isError: isCreateError, error: createError } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => caregiversApi.create({ ...values }),
    onSuccess: () => { window.location.href = '/caregivers'; },
  },
);

const { mutate: updateCaregiver, isError: isUpdateError, error: updateError } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => caregiversApi.update({ ...values, id: props.caregiver?.id }),
    onSuccess: () => { window.location.href = `/caregivers/${props.caregiver?.id}`; },
  },
);

const onSubmit = handleSubmit(() => {
  if (props.mode === 'edit') {
    updateCaregiver();
  } else {
    createCaregiver();
  }
});

function errorMessage(error: AxiosError) {
  if (error.response?.data?.type === 'ActiveRecord::RecordNotUnique') {
    return t('caregiverForm.recordNotUnique');
  }

  return t('userSession.defaultError');
}
</script>

<template>
  <v-container class="v-col-12 v-col-md-8">
    <go-back-button />
    <v-card class="pa-4">
      <v-card-title class="mb-4">
        {{ mode === 'new' ? t('caregiverForm.createTitle') : t('caregiverForm.updateTitle') }}
      </v-card-title>
      <v-form
        class="w-100 ma-0"
        @submit.prevent="onSubmit"
      >
        <v-text-field
          v-if="currentUser?.companyUsesIdCode"
          v-model.number="idCode"
          v-bind="idCodeAttrs"
          type="number"
          variant="outlined"
          :label="`${t('caregiver.idCode')}`"
          :error-messages="errors.idCode"
          @wheel="$event.target.blur()"
          @keypress="isNumber($event)"
        />
        <v-text-field
          v-model="firstName"
          v-bind="firstNameAttrs"
          variant="outlined"
          :label="`${t('caregiver.firstName')} (requerido)`"
          :error-messages="errors.firstName"
        />
        <v-text-field
          v-model="lastName"
          v-bind="lastNameAttrs"
          variant="outlined"
          :label="`${t('caregiver.lastName')} (requerido)`"
          :error-messages="errors.lastName"
        />
        <v-text-field
          v-model="email"
          v-bind="emailAttrs"
          variant="outlined"
          :label="t('caregiver.email')"
          :error-messages="errors.email"
        />
        <v-checkbox
          v-model="passwordIsSet"
          v-bind="passwordIsSetAttrs"
          color="primary"
          class="mt-n6"
          :label="`${t('caregiverForm.passwordIsSet')}`"
          :error-messages="errors.passwordIsSet"
        />
        <v-text-field
          v-model="phoneNumber"
          v-bind="phoneNumberAttrs"
          variant="outlined"
          :label="t('caregiver.phoneNumber')"
          :error-messages="errors.phoneNumber"
        />
        <v-text-field
          v-model="address"
          v-bind="addressAttrs"
          variant="outlined"
          :label="t('caregiver.address')"
          :error-messages="errors.address"
        />
        <v-autocomplete
          v-model="communeId"
          v-bind="communeIdAttrs"
          variant="outlined"
          item-title="name"
          item-value="id"
          :label="t('caregiver.communeId')"
          :error-messages="errors.communeId"
          :items="communes"
        />
        <v-text-field
          v-model="dateOfBirth"
          type="date"
          v-bind="dateOfBirthAttrs"
          variant="outlined"
          :label="t('caregiver.dateOfBirth')"
          :error-messages="errors.dateOfBirth"
        />
        <v-text-field
          v-model="dni"
          v-bind="dniAttrs"
          variant="outlined"
          :label="t('caregiver.dni')"
          :error-messages="errors.dni"
        />
        <v-radio-group
          v-model="sex"
          v-bind="sexAttrs"
          :label="t('caregiver.sex')"
          inline
        >
          <v-radio
            :label="t('caregiverForm.female')"
            value="female"
          />
          <v-radio
            :label="t('caregiverForm.male')"
            value="male"
          />
        </v-radio-group>
        <v-autocomplete
          v-model="countryId"
          v-bind="countryIdAttrs"
          variant="outlined"
          item-title="name"
          item-value="id"
          :label="`${t('caregiver.countryId')} (requerido)`"
          :error-messages="errors.countryId"
          :items="countries"
        />
        <v-select
          v-model="career"
          v-bind="careerAttrs"
          variant="outlined"
          :label="t('caregiver.career')"
          :error-messages="errors.career"
          :items="careers"
        />
        <v-textarea
          v-model="description"
          v-bind="descriptionAttrs"
          variant="outlined"
          :label="t('caregiver.description')"
          :error-messages="errors.description"
        />
        <v-textarea
          v-model="comments"
          v-bind="commentsAttrs"
          variant="outlined"
          :label="t('caregiver.comments')"
          :error-messages="errors.comments"
        />
        <v-btn
          color="primary"
          type="submit"
        >
          {{ mode === 'new' ? t('caregiverForm.createSubmit') : t('caregiverForm.updateSubmit') }}
        </v-btn>
      </v-form>
      <v-alert
        v-if="isCreateError && createError"
        class="mt-4 rounded"
        :type="'error'"
      >
        {{ errorMessage(createError) }}
      </v-alert>
      <v-alert
        v-if="isUpdateError && updateError"
        class="mt-4 rounded"
        :type="'error'"
      >
        {{ errorMessage(updateError) }}
      </v-alert>
    </v-card>
  </v-container>
</template>
